





import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Drill extends Vue {
  @Prop()
  title?: string

  @Prop()
  dummy_image?: string
}
