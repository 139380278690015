



import { Component, Vue } from 'vue-property-decorator'
import Drill from '@/components/organisms/study/Drill.vue'

@Component({
  components: {
    Drill,
  },
})
export default class Drill1 extends Vue {}
